


















































































import { Vue, Component} from "vue-property-decorator";

import { NewsFeedModule as newsStore } from "@/store/modules";

@Component
export default class NoticeLists extends Vue {
    get notices() {
    return newsStore.paginatedNewsList;
    }

    async created() {
        await newsStore.getAllNewsFeed();
    }

    async handlePageChange(val: number) {
        await newsStore.getAllNewsFeed({ page: val });
    }
}


